.gnavi{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
	min-height: 640px;
	padding: 0 8.8vw;
	padding-top: 90px;
    z-index: 1000;
    background: #FFF;
	@include flex();
	align-items: center;
	justify-content: center;
	visibility: hidden;
	&.active{
		visibility: visible;
	}
	@media screen and (max-width: $tab){
		padding: 0 $tab-side;
		padding-top: 80px;
		align-items: flex-start;
		overflow: auto;
		min-height: 1px;
	}
	@media screen and (max-width: $phone){
		padding: 0 $sp-side;
		padding-top: 64px;
	}
	&:before{
		content: '';
		width: 100%;
		height: 1px;
		background: $black;
		position: absolute;
		top: 90px;
		left: 0;
		@media screen and (max-width: $tab){
			top: 80px;	
		}
		@media screen and (max-width: $phone){
			top: 64px;	
		}
	}

	&__desc{
		width: 360px;
		@media screen and (max-width: $tab){
			width: 100%;
			order: 2;
		}
		h1{
			font-size: 1.64rem;
			font-weight: 700;
			line-height: 1;
			margin: 0 0 32px;
			@media screen and (max-width: $tab){
				width: 100%;
				margin: 0 0 24px;
			}
			@media screen and (max-width: $phone){
				font-size: 1.32rem;
				margin: 0 0 16px;
			}
			&:before{
				content: attr(data-eng);
				font-size: .72rem;
				font-weight: 400;
				display: block;
				margin: 0 0 12px;
				transform: translateX(.2em);
				@media screen and (max-width: $phone){
					margin: 0 0 8px;	
				}
			}
		}
		.desc{
			margin: 28px 0 0;
			font-size: .92rem;
			text-align: justify;
			@media screen and (max-width: $tab){
				padding: 0 0 $tab-side*2;
			}
			@media screen and (max-width: $phone){
				margin: 16px 0 0;
				padding: 0 0 $sp-side*2;
			}
		}
		dl{
			@include flex();
			justify-content: flex-start;
			padding: 24px 0;
			border-bottom: $normal 1px solid;
			position: relative;
			font-size: 1.02rem;
			@media screen and (max-width: $tab){
				border-top: $normal 1px solid;
				border-bottom: none;
			}
			@media screen and (max-width: $phone){
				padding: 16px 0;	
			}
			&:first-of-type{
				border-top: $normal 1px solid;
			}
			&:last-of-type{
				border-bottom: $normal 1px solid;
			}
			dt{
				width: 80px;
				font-weight: 700;
			}
			dd{
				width: calc(100% - 80px);
				a{
					display: inline-block;
				}
				i{
					display: inline-block;
					margin: 0 0 0 8px;
					font-size: .72rem;
				}
			}
		}
	}
	&__list{
		width: calc(100% - 360px);
		padding: 0 0 0 8.8vw;
		@media screen and (max-width: $tab){
			width: 100%;
			padding: 0;
			order: 1;
		}
		@media screen and (max-width: $phone){
			margin: 0 0 8px;	
		}
		ul{
			@media screen and (max-width: $tab){
				@include flex();
				padding: $tab-side*2 0;
			}
			@media screen and (max-width: $phone){
				padding: $sp-side*2 0;	
			}
			li{
				margin: 0 0 44px;
				@media screen and (max-width: $tab){
					width: calc(50% - #{$tab-side});	
				}
				@media screen and (max-width: $phone){
					width: calc(50% - #{$sp-side});
					margin: 0 0 20px;	
				}
				&:last-child{
					margin: 0;
				}
				a{
					font-size: 1.2rem;
					line-height: 1;
					font-weight: 700;
					letter-spacing: .048rem;
					padding: 0 40px 0 0;
					display: block;
					position: relative;
					@media screen and (max-width: $phone){
						font-size: 1rem;
						line-height: 1.4;
						padding: 0 24px 0 0;
					}
					&:before{
						content: attr(data-eng);
						font-size: 3.6rem;
						display: inline-block;
						margin: 0 32px 0 0;
						@media screen and (max-width: $tab){
							font-size: 2rem;	
						}
						@media screen and (max-width: $phone){
							font-size: .88rem;
							display: block;
						}
					}
					&:after{
						content: '';
						width: 0;
						height: 2px;
						background: $normal;
						position: absolute;
						left: auto;
						right: 0;
						bottom: -4px;
						transition: width 400ms ease;
					}
					i{
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
						font-size: 1.2rem;
					}
					&:hover{
						color: $normal;
						&:after{
							width: 100%;
							left: 0;
							right: auto;
						}
					}
				}
			}
		}
	}
}

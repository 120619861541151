.site-recruit{
  .localnav{
    background: $gray;
    padding: 16px 40px;
    @include flex();
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    @media screen and (max-width: $phone){
      padding: 15px;
      display: block;
    }
    li{
      margin: 0 16px 0 0;
      @media screen and (max-width: $phone){
        margin: 0 0 12px;
      }
      &:last-child{
        margin: 0;
      }
      a{
        display: block;
        padding: 12px 32px;
        min-width: 280px;
        font-size: 1.2rem;
        text-align: center;
        background: #FFF;
        border: #FFF 2px solid;
        border-radius: 4px;
        box-shadow: 0 0 32px rgba($normal,.08);
        @media screen and (max-width: $phone){
          min-width: 1px;
        }
        &:hover{
          border-color: $normal;
        }
        .rt{
          transform: rotateY(180deg);
        }
        i{
          display: inline-block;
          margin: 0 8px 0 0;
        }
      }
    }
  }

  .tabs{
    &__top{
      margin: 0 0 40px;
      @media screen and (max-width: $phone){
        margin: 0 0 12px;
      }
      &__tab{
        @include flex();
        justify-content: center;
        align-items: center;
        @media screen and (max-width: $phone){
          justify-content: space-between;
        }
        li{
          cursor: pointer;
          min-width: 240px;
          text-align: center;
          padding: 14px 0;
          font-size: 1.16rem;
          font-weight: 700;
          background: $gray;
          border: $gray 2px solid;
          margin: 0 8px 0 0;
          position: relative;
          transition: all 160ms $ease;
          @media screen and (max-width: $phone){
            min-width: 1px;
            width: calc(50% - 8px);
            margin: 0 0 12px;
            padding: 8px 0;
            font-size: .92rem;
          }
          &:before{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 16px 12px 0 12px;
            border-color: $normal transparent transparent transparent;
            position: absolute;
            left: 50%;
            bottom: -8px;
            transform: translate(-50% , 100%);
            opacity: 0;
            transition: all 160ms 40ms $ease;
            @media screen and (max-width: $phone){
              display: none;
            }
          }
          &.active{
            border-color: $normal;
            color: $normal;
            box-shadow: 0 0 16px rgba($normal,.12);
            z-index: 2;
            &:before{
              bottom: 0;
              opacity: 1;
            }
          }
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
    &__box{
      background: #FFF;
      &.invisible{
        display: none !important;
      }
      table.tablestyle{
        tbody{
          th,td{
            font-size: 1.12rem;
            @media screen and (max-width: $phone){
              font-size: 1.04rem;
            }
          }
          th{
            background: $gray;
						text-align: center;
            @media screen and (max-width: $phone){
              text-align: left;
            }
          }
					td{
						.inner{
							width: 420px;
							margin: 0 auto;
							@media screen and (max-width: $phone){
								width: auto;
							}
						}
					}
        }
      }
    }
  }
}
.site-company{
  .message{
    padding: 80px 40px;
    margin: 48px 0 0;
    background: $gray url(/images/common/tex_bg.jpg) repeat 0 0 / 256px;
    border: rgba($normal,.04) 1px solid;
    text-shadow: 0 1px 0 #FFF;
    @media screen and (max-width: $phone){
      padding: 40px 20px;
      margin: 32px 0 0;
    }
    &__title{
      text-align: center;
      font-size: 1.92rem;
      font-weight: 700;
      margin: 0 0 56px;
      @media screen and (max-width: $phone){
        font-size: 1.44rem;
        margin: 0 0 32px;
      }
    }
    &__context{
      max-width: 720px;
      margin: 80px auto 0;
      @media screen and (max-width: $phone){
        margin: 40px auto 0;
      }
      &.p{
        // USE RECRUIT
        max-width: 600px;
        font-size: 1.08rem;
        line-height: 2.1;
        @media screen and (max-width: $phone){
          font-size: 1.04rem;
        }
      }
      &:first-of-type{
        margin-top: 0;
      }
      dt{
        font-size: 1.56rem;
        font-weight: 700;
        line-height: 1.56;
        padding: 0 0 8px;
        margin: 0 0 20px;
        border-bottom: $normal 3px solid;
        position: relative;
        @media screen and (max-width: $phone){
          font-size: 1.32rem;
        }
      }
      dd{
        margin: 2rem 0 0;
        font-size: 1.08rem;
        line-height: 2.1;
        @media screen and (max-width: $phone){
          font-size: 1.04rem;
        }
        &:first-of-type{
          margin-top: 0;
        }
      }
    }
    .name{
      text-align: right;
      @include min();
      font-weight: 700;
      font-size: 1rem;
      @media screen and (max-width: $phone){
        font-size: .88rem;
      }
      &.p{
        max-width: 600px;
        margin: 40px auto 0;
        @media screen and (max-width: $phone){
          margin: 24px auto 0;
        }
      }
      strong{
        font-size: 2rem;
        line-height: 1;
        display: inline-block;
        margin: 0 0 0 12px;
        @media screen and (max-width: $phone){
          font-size: 1.32rem;
        }
      }
    }
  }
}
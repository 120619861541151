.site-works{
  .worklist{
    margin: 0 0 0 -32px;
    @include flex();
    justify-content: flex-start;
    @media screen and (max-width: $phone){
      margin: 0;
      display: block;
    }
    > li{
      width: 50%;
      padding: 0 0 0 32px;
      margin: 48px 0 0;
      @media screen and (max-width: $tab){
        width: 50%;
      }
      @media screen and (max-width: $phone){
        width: auto;
        padding: 0;
        margin: 0 0 24px;
        padding: 0 0 20px;
        border-bottom: $border 1px solid;
      }
      &:nth-child(-n+2){
         margin-top: 0;
      }
      figure{
        width: 100%;
        padding: calc(100% / 16 * 9) 0 0;
        position: relative;
        border-radius: 2px;
        overflow: hidden;
        background: $gray;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      > p{
        display: block;
        text-align: center;
        font-size: .96rem;
        padding: 16px 0 0;
        @media screen and (max-width: $phone){
          text-align: left;
        }
      }
    }
  }
}
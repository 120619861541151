.site-business{
  > section{
    &.border{
      border-bottom: $border 1px solid;
    }
    .bus-title{
      text-align: center;
      font-size: 2.44rem;
      font-weight: 700;
      line-height: 1;
      margin: 0 0 32px;
      @media screen and (max-width: $phone){
        font-size: 1.72rem;
      }
      &:after{
        content: attr(data-eng);
        font-size: .92rem;
        text-transform: uppercase;
        display: block;
        margin: 14px 0 0;
        letter-spacing: .16em;
        opacity: .32;
        @media screen and (max-width: $phone){
          margin: 10px 0 0;
        }
      }
      &.m{
        font-size: 1.96rem;
        margin: 0 0 24px;
        text-align: left;
        padding: 0 0 24px;
        border-bottom: #FFF 4px solid;
        @media screen and (max-width: $phone){
          font-size: 1.24rem;
          margin: 0 0 20px;
          padding: 0 0 20px;
        }
        &:after{
          display: inline-block;
          margin: 0 0 0 16px;
          @media screen and (max-width: $phone){
            margin: 0 0 0 12px;
            font-size: .8rem;
          }
        }
      }
    }
  }
  .localist{
    @include flex();
    justify-content: flex-start;
    margin: 0 0 0 -20px;
    @media screen and (max-width: $tab){
      display: block;
      margin: 0;
    }
    &__item{
      width: 25%;
      padding: 0 0 0 20px;
      height: auto;
      @include flex();
      @media screen and (max-width: $tab){
        width: 50%;
        margin: 20px 0 0;
        &:nth-child(-n+2){
          margin-top: 0;
        }
      }
      @media screen and (max-width: $phone){
        width: auto;
        padding: 0;
        margin: 0 0 12px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      dl{
        background: $gray;
        width: 100%;
        border-radius: 3px;
        padding: 20px 24px;
        padding-bottom: 80px;
        position: relative;
        @media screen and (max-width: $phone){
          padding-bottom: 72px;
        }
        dt{
          text-align: center;
          font-size: 1.16rem;
          font-weight: 700;
          margin: 0 0 4px;
        }
        dd{
          font-size: .88rem;
          line-height: 1.56;
          letter-spacing: 0;
          text-align: center;
          &.btn{
            position: absolute;
            left: 24px;
            bottom: 20px;
            width: calc(100% - 24px * 2);
            a{
              display: block;
              background: #FFF;
              color: $normal;
              padding: 9px 0;
              border-radius: 3px;
              box-shadow: 0 0 16px rgba($normal, .08);
              &:hover{
                background: $normal;
                color: #FFF;
              }
            }
          }
        }
      }
    }
  }
  .buslist{
    @include flex();
    padding: 0 0 64px;
    margin: 0 0 64px;
    border-bottom: $border 1px solid;
    @media screen and (max-width: $phone){
      padding: 0 0 32px;
      margin: 0 0 32px;
      display: block;
    }
    &__item{
      width: calc(50% - 12px);
      background: $gray;
      padding: 32px;
      margin: 24px 0 0;
      @include flex();
      border-radius: 3px;
      @media screen and (max-width: $phone){
        padding: 16px;
        width: auto;
        margin: 0 0 12px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      &:nth-child(-n+2){
        margin-top: 0;
      }
      &__icon{
        width: 72px;
        height: 72px;
        background: #FFF;
        @include flex();
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        @media screen and (max-width: $phone){
          width: 56px;
          height: 56px;
        }
        svg{
          height: 36px;
          @media screen and (max-width: $phone){
            height: 28px;
          }
        }
      }
      dl{
        width: calc(100% - 72px);
        padding: 0 0 0 32px;
        @media screen and (max-width: $phone){
          width: calc(100% - 56px);
          padding: 0 0 0 20px;  
        }
        dt{
          font-size: 1.32rem;
          font-weight: 700;
          margin: 0 0 8px;
          @media screen and (max-width: $phone){
            font-size: 1.24rem;
            margin: 0 0 4px;
          }
        }
        dd{
          font-size: .96rem;
        }
      }
    }
  }
  .secbox{
    margin: 40px 0 0;
    background: $gray;
    padding: 56px;
    @include flex();
    @media screen and (max-width: $phone){
      width: auto;
      padding: 16px;
      margin: 24px 0 0;
      display: block;
    }
    &__pic{
      width: 300px;
      @media screen and (max-width: $phone){
        width: auto;
      }
      img{
        width: 100%;
      }
    }
    &__data{
      width: calc(100% - 300px);
      padding: 0 0 0 48px;
      @media screen and (max-width: $phone){
        width: auto;
        padding: 20px 8px 0;
      }
    }
  }
}
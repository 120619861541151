.site-index{
	.firstview{
		width: 100%;
		height: 100vh;
		min-height: 640px;
		overflow: hidden;
		@include flex();
		align-items: flex-end;
		justify-content: flex-start;
		position: relative;
		@media screen and (max-width: $tab){
			min-height: 1px;	
		}
		@media screen and (max-width: $phone){
			align-items: center;	
		}
		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $black;
			opacity: .08;
			z-index: 2;
		}
		&:after{
			background: none;
			box-shadow: inset 0 25vmax 50vmax $black;
			opacity: .32;
		}
		video{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			z-index: 1;
		}
		&__content{
			z-index: 3;
			padding: 0 0 $pc-side 4vw;
			color: #FFF;
			text-shadow: 0 0 32px rgba($black, .08);
			@media screen and (max-width: $tab){
				padding: 0 0 $tab-side 6vw;
			}
			@media screen and (max-width: $phone){
				padding: 0 0 0 6vw;	
			}
			&__tt{
				font-weight: 700;
				font-size: 1.44rem;
				text-indent: .36em;
				margin: 0 0 8px;
				@media screen and (max-width: $phone){
					font-size: 1.12rem;
					text-indent: 0;
				}
			}
			&__catch{
				font-weight: 700;
				font-size: 8.4rem;
				text-transform: uppercase;
				letter-spacing: 0;
				line-height: .94;
				white-space: nowrap;
				@media screen and (max-width: $tab){
					font-size: 5.6rem;	
				}
				@media screen and (max-width: $phone){
					font-size: 3.2rem;
					line-height: 1;
				}
			}
		}
		&__scroll{
			position: absolute;
			right: $pc-side;
			bottom: 0;
			font-size: .72rem;
			color: #FFF;
			text-transform: uppercase;
			letter-spacing: .32em;
			-webkit-writing-mode: vertical-rl;
			-moz-writing-mode: vertical-rl;
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
			white-space: nowrap;
			padding: 0 8px 64px 0;
			z-index: 3;
			@media screen and (max-width: $phone){
				padding-bottom: 40px;
				right: $sp-side;
			}
			&:before{
				content: '';
				position: absolute;
				bottom: 0;
				right: 0;
				background: #FFF;
				width: 1px;
				height: 100%;
				animation: sc 1600ms $ease infinite;
				@keyframes sc{
					0%{
						height: 100%;
						bottom: 0;
					}
					45%{
						height: 0;
						bottom: 0;
					}
					55%{
						height: 0;
						bottom: 100%;
					}
					100%{
						height: 100%;
						bottom: 0;
					}
				}
			}
		}
		&__crone{
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 100%;
			background: #FFF;
			z-index: 10;
		}
	}
}
#site-header{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 90px;
	z-index: 2000;
	@include flex();
	align-items: center;
	@media screen and (max-width: $tab){
		height: 80px;
	}
	@media screen and (max-width: $phone){
		height: 64px;
		background: #FFF;
	}
	&.active,
	&.sc{
		.logo{
			svg{
				path{
					fill: $normal;
				}
			}
		}
		.contact{
			border-color: $normal;
			color: $normal;
			&:hover{
				background: $normal;
				border-color: $normal;
				color: #FFF;
			}
		}
	}
	.logo{
		width: 220px;
		margin: 0 auto;
		@media screen and (max-width: $phone){
			width: 164px;
			margin: 0 0 0 $sp-side;
		}
		svg{
			width: 100%;
			height: auto;
			vertical-align: bottom;
			path{
				fill: #FFF;
				transition: fill 200ms $ease;
				@media screen and (max-width: $phone){
					fill: $normal;	
				}
			}
		}
	}
	.menu{
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 $pc-side;
		height: 100%;
		@include flex();
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
		color: #FFF;
		@media screen and (max-width: $tab){
			padding: 0 $tab-side;	
		}
		@media screen and (max-width: $phone){
			left: auto;
			right: 0;
			padding: 0 $sp-side*1.5;
		}
		&:before,
		&:after{
			content: '';
			width: 0;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			transition: width 200ms $ease, border-radius 200ms $ease;
		}
		&:before{
			width: 100%;
			background: $black;
		}
		&:after{
			background: $high;
			border-radius: 0 100% 100% 0;
		}
		&:hover{
			&:after{
				width: 100%;
				border-radius: 0;
			}
		}
		&__icon{
			width: 24px;
			height: 14px;
			margin: 0 16px 0 0;
			position: relative;
			user-select: none;
			@media screen and (max-width: $phone){
				margin: 0;	
			}
			&__center,
			&:before,
			&:after{
				content: '';
				width: 100%;
				height: 1px;
				background: #FFF;
				position: absolute;
				border-radius: 999px;
				left: 0;
				transition: all 200ms $ease;
			}
			&__center{
				top: 50%;
				transform: translateY(-50%);
			}
			&:before{
				top: 0;
			}
			&:after{
				bottom: 0;
			}
			&.active{
				&:before{
					transform: rotate(45deg);
					top: 50%;
				}
				&:after{
					transform: rotate(-45deg);
					bottom: 50%;
				}
				.menu__icon__center{
					width: 0;
				}
			}
		}
		&__title{
			font-size: 1.04rem;
			transform: translateY(-1px);
			@media screen and (max-width: $phone){
				display: none;
			}
		}
	}
	
	.contact{
		position: absolute;
		top: 50%;
		right: $pc-side;
		color: #FFF;
		border: #FFF 1px solid;
		padding: 7px 32px;
		font-size: 1.04rem;
		transform: translateY(-50%);
		transition: color 200ms $ease, border 200ms $ease, background 200ms $ease;
		@media screen and (max-width: $tab){
			right: $tab-side;	
		}
		@media screen and (max-width: $phone){
			display: none;
		}
		i{
			display: inline-block;
			margin: 0 12px 0 0;
		}
		&:hover{
			background: #FFF;
			color: $normal;
		}
	}	
}

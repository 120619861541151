#site-footer{
	background: $normal;
	color: #FFF;
	a{
		color: #FFF;
	}
	.map{
		filter: grayscale(1);
		background: $gray;
		iframe{
			width: 100%;
			height: 360px;
			vertical-align: bottom;
			@media screen and (max-width: $phone){
				height: 240px;	
			}
		}
	}
	.pagetop{
		color: #FFF;
		text-align: center;
		padding: 16px 0;
		display: block;
		position: relative;
		background: rgba(#FFF,.04);
		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(#FFF,.08);
			opacity: 0;
		}
		&:hover{
			&:before{
				opacity: 1;
			}
		}
	}
	
	.company{
		@include flex();
		align-items: center;
		justify-content: center;
		margin: 64px 0 0;
		position: relative;
		@media screen and (max-width: $phone){
			margin: 32px $sp-side;
			display: block;
		}
		&__title{
			margin: 0 24px 0 0;
			padding: 0 24px 0 0;
			border-right: rgba(#FFF,.16) 1px solid;
			@media screen and (max-width: $phone){
				border: none;
				margin: 0;
				padding: 0;
				text-align: center;
			}
			dt{
				font-size: 1.48rem;
				font-weight: 700;
				line-height: 1;
				margin: 0 0 5px;
				@media screen and (max-width: $phone){
					font-size: 1.24rem;	
				}
			}
			dd{
				font-size: .8rem;
			}
		}
		&__meta{
			font-size: 1rem;
			@media screen and (max-width: $phone){
				margin: 8px 0 0;	
			}
			li{
				margin: 0 20px 0 0;
				@media screen and (max-width: $phone){
					margin: 0;
					text-align: center;
				}
				&:last-child{
					margin: 0;
				}
			}
		}
	}

	.fnav{
		@include flex();
		align-items: center;
		justify-content: center;
		padding: 64px 0;
		@media screen and (max-width: $phone){
			padding: 0;
			display: block;
			border: lighten($normal, 12%) 1px solid;
			margin: 0 $sp-side 32px;
			border-radius: 4px;
		}
		> li{
			@media screen and (max-width: $phone){
				border-bottom: lighten($normal, 12%) 1px solid;
			}
			&:after{
				content: '|';
				display: inline-block;
				margin: 0 40px;
				opacity: .12;
				@media screen and (max-width: $phone){
					display: none;
				}
			}
			&:last-child{
				@media screen and (max-width: $phone){
					border: none;
				}
				&:after{
					display: none;
				}
			}
			a{
				@media screen and (max-width: $phone){
					display: block;
					padding: $sp-side*.75 $sp-side;
				}
				&:hover{
					text-decoration: underline;
				}
				i{
					font-size: .8rem;
					transform: scale(.9);
					display: inline-block;
					color: $normal;
					margin: 0 0 0 6px;
				}
			}
		}
	}
	
	.copy{
		display: block;
		padding: 24px 0;
		text-align: center;
		font-size: .72rem;
		border-top: rgba(#FFF,.08) 1px solid;
	}

}
.site-index{
	.firstSec{
		&__flex{
			position: relative;
			padding: $pc-top 0;
			@media screen and (max-width: $tab){
				padding: $tab-top 0;
			}
			@media screen and (max-width: $phone){
				padding: $sp-top 0 0;	
			}
			&:before{
				content: '';
				width: 90%;
				height: 100%;
				position: absolute;
				top: 0;
				left: calc(-4vw - #{$pc-side});
				background: $gray;
				z-index: -1;
				@media screen and (max-width: $tab){
					left: calc(-4vw - #{$tab-side});
				}
				@media screen and (max-width: $phone){
					display: none;
				}
			}
			&__pic{
				width: 72%;
				height: calc(100% - #{$pc-top*2});
				position: absolute;
				top: $pc-top;
				left: -$pc-side;
				@media screen and (max-width: $tab){
					height: calc(100% - #{$tab-top*2});
					top: $tab-top;
					left: -$tab-side;
				}
				@media screen and (max-width: $phone){
					position: relative;
					left: -$sp-side;
					top: 0;
					width: 100%;
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
			&__eng{
				position: absolute;
				top: $pc-top*.33;
				right: $pc-side;
				font-size: 12rem;
				line-height: 1;
				font-weight: 700;
				color: #d8d8d8;
				@media screen and (max-width: $tab){
					font-size: 8.8rem;
					top: $tab-top*.5;
					right: $tab-side;
				}
				@media screen and (max-width: $phone){
					font-size: 3.2rem;
					top: $sp-top*.5;
					right: 0;
				}
			}
			&__data{
				margin: $pc-top/2 0;
				margin-left: 50%;
				min-width: 520px;
				max-width: 620px;
				padding: 64px 56px;
				background: #FFF;
				position: relative;
				z-index: 2;
				@media screen and (max-width: $tab){
					margin: $tab-top/2 0;
					margin-left: 50%;
					min-width: 1px;
					max-width: 50%;
					padding: 32px　48px;
					padding-right: 0;
				}
				@media screen and (max-width: $phone){
					margin: $sp-top/2 0;
					max-width: 100%;
					padding: $sp-side;
					padding-bottom: 0;
				}
				h2{
					font-size: 2rem;
					line-height: 1.44;
					font-weight: 700;
					@media screen and (max-width: $tab){
						font-size: 1.66rem;	
					}
					@media screen and (max-width: $phone){
						font-size: 1.44rem;
						text-align: center;
					}
				}
				.desc{
					text-align: justify;
					margin: 16px 0 0;
					@media screen and (max-width: $tab){
						font-size: .96rem;	
					}
				}
			}
		}
	}
	
	.secondSec{
		position: relative;
		@media screen and (max-width: $phone){
			padding-top: $sp-top !important;	
		}
		&:before{
			content: '';
			width: 16vw;
			height: 100%;
			background: $gray;
			position: absolute;
			top: 0;
			right: 0;
			z-index: -1;
			@media screen and (max-width: $phone){
				width: 88%;
				height: 120px;
			}
		}

		&__flex{
			@include flex();
			align-items: center;
			position: relative;
			margin: 0 auto;
			&__pics{
				width: 56%;
				@include flex();
				align-items: center;
				justify-content: flex-end;
				top: 0;
				right: 0;
				@media screen and (max-width: $phone){
					width: 100%;
					order: 1;
				}
				li{
					width: 50%;
					transform: translateX(40px);
					position: relative;
					z-index: 3;
					@media screen and (max-width: $phone){
						transform: translate(0) !important;
					}
					&:last-child{
						transform: translateY(#{-$pc-top*1.25});
						z-index: 2;
						@media screen and (max-width: $tab){
							transform: translateY(#{-$tab-top*1.25});
						}
					}
					img{
						width: 100%;
						@media screen and (max-width: $phone){
							transform: translate(0) !important;
						}
					}
				}
			}
			&__data{
				width: 44%;
				max-width: 620px;
				padding: 0 20px 0 56px;
				position: relative;
				@media screen and (max-width: $tab){
					padding: 0;	
				}
				@media screen and (max-width: $phone){
					width: 100%;
					max-width: 100%;
					padding: $sp-side;
					order: 2;
				}
				&:before{
					content: '';
					width: 120px;
					height: 120px;
					@include ami(rgba($normal,.99),4px);
					position: absolute;
					top: 0;
					left: 0;
					transform: translateY(-50%);
					z-index: -1;
					@media screen and (max-width: $tab){
						display: none;
					}
				}
				h2{
					font-size: 2rem;
					line-height: 1.5;
					font-weight: 700;
					padding: 0 0 12px;
					position: relative;
					z-index: 2;
					@media screen and (max-width: $tab){
						font-size: 1.66rem;	
					}
					@media screen and (max-width: $phone){
						font-size: 1.44rem;
						text-align: center;
						margin: $sp-side 0 0;
					}
				}
				.desc{
					font-size: 1.06rem;
					text-align: justify;
					@media screen and (max-width: $phone){
						font-size: .96rem;	
					}
				}
			}
		}
		&__list{
			margin: 4vmax 0 0;
			@include ami(rgba($normal,.99),4px);
			padding: 24px 32px;
			@media screen and (max-width: $phone){
				margin: $sp-side*1.5 0 0;
				padding: $sp-side;
			}
			&__box{
				@include flex();
				align-items: center;
				padding: 24px 32px;
				background: #FFF;
				position: relative;
				@media screen and (max-width: $phone){
					padding: $sp-side*1.5;
					display: block;
				}
				&:before{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 12px 12px 0 0;
					border-color: $normal transparent transparent transparent;
					position: absolute;
					top: 6px;
					left: 6px;
				}
				&__title{
					width: 220px;
					font-size: 1.12rem;
					font-weight: 700;
					border-right: $border 1px solid;
					text-align: center;
					@media screen and (max-width: $phone){
						width: auto;
						border: none;
						margin: 0 0 12px;
					}
				}
				&__data{
					width: calc(100% - 220px);
					padding: 0 0 0 64px;
					@include flex();
					justify-content: flex-start;
					@media screen and (max-width: $phone){
						width: auto;
						padding: 0;
					}
					li{
						&:after{
							content: '、';
						}
						&:last-child{
							&:after{
								display: none;
							}
						}
					}
				}
			}
		}
	}
	.works{
		width: 100%;
		overflow: hidden;
		position: relative;
		&__flex{
			@include flex();
			align-items: center;
			position: relative;
			@media screen and (max-width: $phone){
				align-items: flex-start;	
			}
			&__title{
				width: 420px;
				padding: 0 $pc-side 0 $pc-side*2;
				text-align: center;
				position: relative;
				z-index: 2;
				@media screen and (max-width: $tab){
					width: 360px;
					padding: 0 $tab-side 0 $tab-side*2;
				}
				@media screen and (max-width: $phone){
					width: 100%;
					order: 2;
					padding: $sp-side*2;
					padding-bottom: 0;
					text-align: justify;
				}
				h2{
					font-size: 1.2rem;
					font-weight: 700;
					margin: 0 0 16px;
					@media screen and (max-width: $phone){
						margin: 0 0 8px;
						text-align: center;
					}
					&:before{
						font-weight: 700;
						content: attr(data-eng);
						font-size: 3.24rem;
						margin: 0 0 4px;
						line-height: 1;
						display: block;
						@media screen and (max-width: $phone){
							font-size: 2.4rem;	
						}
					}
				}
				.btnarea{
					a{
						display: block;
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
			&__pics{
				width: calc(100% - 420px);
				transform: translateX(#{$pc-side});
				position: relative;
				z-index: 2;
				@media screen and (max-width: $tab){
					width: calc(100% - 360px);
					transform: translateX(#{$tab-side});
				}
				@media screen and (max-width: $phone){
					width: 100%;
					transform: translate(0);
				}
				.swiper-wrapper{
					transition-timing-function: $ease;
				}
				.item{
					width: auto !important;
					margin: 0 2px 0 0;
					img{
						width: auto;
						height: 440px;
						@media screen and (max-width: $tab){
							height: 380px;	
						}
						@media screen and (max-width: $phone){
							height: 200px;
						}
					}
				}
			}
		}
	}
	
	.recruit{
		margin-top: $pc-top;
		background: $normal;
		position: relative;
		overflow: hidden;
		@media screen and (max-width: $phone){
			margin-top: $sp-top;	
		}
		&:after{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: $normal;
			opacity: .08;
		}
		&__bg{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: url(/images/index/recl_bg.jpg) no-repeat center / cover;
		}
		&__data{
			position: relative;
			z-index: 2;
			color: #FFF;
			padding: 5vh 10.5vw;
			@media screen and (max-width: $tab){
				padding: 5vh $tab-side;
			}
			@media screen and (max-width: $phone){
				padding: 4vh $sp-side*1.5;	
			}
			dt{
				font-size: 1.44rem;
				font-weight: 700;
				line-height: 1;
				margin: 0 0 32px;
				@media screen and (max-width: $phone){
					font-size: 1.32rem;	
				}
				&:before{
					content: attr(data-eng);
					font-size: 3.6rem;
					letter-spacing: .02em;
					margin: 0 0 12px;
					display: block;
					@media screen and (max-width: $phone){
						font-size: 2.4rem;	
					}
				}
			}
			dd{
				font-size: 1.16rem;
				@media screen and (max-width: $phone){
					font-size: 1rem;
					max-width: 72%;
				}
				&.btnarea{
					a{
						border: #FFF 1px solid;
						&:before{
							display: none;
						}
					}
				}
			}
		}
	}
	.news{
        position: relative;
        z-index: 2;
		color: $normal;
		&.wrap{
			padding-top: 80px;
			padding-bottom: 80px;
			@media screen and (max-width: $phone){
				padding-top: 40px;
				padding-bottom: 40px;
			}
		}
		.display{
			&.flex{
				@include flex();
				@media screen and (max-width: $phone){
					display: block;	
				}
			}
		}
		.newsbox{
			width: 100%;
			@include flex();
			@media screen and (max-width: $phone){
				display: block;
			}
		}
        .leftside{
            width: 280px;
			@media screen and (max-width: $phone){
				width: auto;
				position: relative;
				margin: 0 0 20px;
			}
			h3{
				line-height: 1;
				font-size: 1rem;
				margin: 0 0 12px;
				font-weight: 700;
				&:before{
					content: attr(data-eng);
					font-weight: 700;
					font-size: 2.4rem;
					display: inline-block;
					margin: 0 24px 0 0;
					transform: translateX(-2px);
					@media screen and (max-width: $phone){
						font-size: 1.44rem;
						margin: 0 12px 0 0;
					}
				}
			}
			a{
				display: inline-block;
				font-weight: 700;
				color: $normal;
				font-size: 1.08rem;
				@media screen and (max-width: $phone){
					position: absolute;
					top: 0;
					right: 0;
				}
				&:hover{
					text-decoration: underline;
				}
				i{
					display: inline-block;
					font-size: .96rem;
					margin: 0 0 0 12px;
				}
			}
        }
        ol{
            width: calc(100% - 280px);
            height: 132px;
            padding: 0 40px 0 0;
            overflow: auto;
			@media screen and (max-width: $tab){
				height: 150px;	
			}
			@media screen and (max-width: $phone){
				width: auto;
				padding: 0;
			}
            li{
                border-bottom: rgba($normal,.08) 1px solid;
                padding: 0 0 24px;
                margin: 0 0 24px;
				@media screen and (max-width: $tab){
					padding: 0 0 15px;
					margin: 0 0 15px;
				}
                &:last-child{
                    margin: 0;
                }
                a{
                    @include flex();
                    align-items: center;
                    color: $normal;
					@media screen and (max-width: $tab){
						display: block;	
					}
                    &:hover{
                        h4{
                            text-decoration: underline;
                        }
                    }
                }
                span.time{
                    width: 14%;
                    font-size: .88rem;
                    background: $normal;
                    color: #FFF;
                    padding: 2px 0;
                    text-align: center;
					@media screen and (max-width: $tab){
						width: 100px;
						display: inline-block;
					}
                }
                h4{
                    width: 86%;
                    font-size: 1.12rem;
                    padding: 0 0 0 32px;
					@media screen and (max-width: $tab){
						width: auto;
						font-size: 1rem;
						padding: 6px 0 0;
					}
                }
            }
        }
	}
	
	.youtube{
		padding: 88px 0;
		margin: 0 0 88px;
		background: $gray;
		@include flex();
		align-items: center;
		justify-content: center;
		@media screen and (max-width: $phone){
			padding: 32px 0;
			margin: 0 0 32px;	
		}
		iframe{
			width: 80vw;
			max-width: 1024px;
			height: calc(80vw / 16 * 9);
			max-height: calc(1024px / 16 * 9);
			vertical-align: bottom;
			border-radius: 16px;
			@media screen and (max-width: $phone){
				width: 90vw;
				height: calc(90vw / 16 * 9);
				border-radius: 6px;
			}
		}
	}
}
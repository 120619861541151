.breadcrumb{
	background: #000;
	height: 420px;
	position: relative;
	overflow: hidden;
	@include flex();
	align-items: center;
	justify-content: center;
	@media screen and (max-width: $phone){
		height: 240px;	
	}
    &__bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
		z-index: 1;
		&:after{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: $normal;
			opacity: .4;
		}
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
			z-index: 1;
        }
    }
	h1{
		font-size: 1.64rem;
		letter-spacing: .12em;
		text-align: center;
		color: #FFF;
		line-height: 1;
		position: relative;
		z-index: 3;
		transform: translateY(16%);
		@media screen and (max-width: $phone){
			font-size: 1.12rem;
			transform: translateY(18px);
		}
		&:before{
			display: block;
			content: attr(data-eng);
			letter-spacing: .008em;
			font-size: 4.4rem;
			font-weight: 700;
			margin: 0 0 12px;
			@media screen and (max-width: $phone){
				font-size: 2rem;
				margin: 0 0 8px;
			}
		}
	}
	&__list{
		position: absolute;
		left: $pc-side;
		bottom: 16px;
		z-index: 2;
		color: #FFF;
		@media screen and (max-width: $phone){
			left: $sp-side;	
		}
        ol{
            @include flex();
            align-items: center;
            justify-content: flex-start;
            li{
                list-style: none;
                font-size: .8rem;
                color: #FFF;
				@media screen and (max-width: $phone){
					font-size: .72rem;	
				}
                &:after{
                    content: "\f054";
                    font-family: "Font Awesome 5 Free";
                    font-size: .64rem;
                    font-weight: 900;
                    opacity: .4;
                    display: inline-block;
                    margin: 0 16px;
                    transform: translateY(-2px);
					@media screen and (max-width: $phone){
						margin: 0 12px;	
					}
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
                a{
                    color: #FFF;
                    &:hover{
                    	text-decoration: underline;
                    }
                    i{
                        display: inline-block;
                        margin: 0 8px 0 0;
                        font-size: .88rem;
                    }
                }
            }
        }
	}
}

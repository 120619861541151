@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

@import "module/reset";
@import "module/module";

//USE ASSETS
@import "plugin/swiper";

/* BASE COLOR
------------------------------------------------------------*/
$normal: #080808;
$link: $normal;
$border:#e8e8e8;

$black: #080808;
$high: lighten($black, 8%);
$gray: #f4f4f5;

$ease: cubic-bezier(0.65, 0, 0.35, 1);

/* SITE SETTING
------------------------------------------------------------*/
$width: 1120px;
$tab: $width - 1px;
$phone: 640px;

$pc-top: 112px;
$tab-top: 80px;
$sp-top: 32px;

$pc-side: 40px;
$tab-side: 32px;
$sp-side: 16px;

:root{
	font-size: 14.5px;
	@media screen and (max-width: $tab){
		font-size: 14px;	
	}
	@media screen and (max-width: $phone){
		font-size: 13.5px;	
	}
}

@media screen and (min-width: $width){
	@include default(
		$width, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $tab){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $phone){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@mixin ami($color, $size){
	background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 15 15"><rect x="0" y="0" width="3" height="3" fill="#{$color}" stroke-width="0" /></svg>');
	background-size: $size;
}

@import "common/header";
@import "common/menu";
@import "common/footer";
@import "common/bread";
@import "page/firstview";
@import "page/index";
@import "page/company";
@import "page/works";
@import "page/business";
@import "page/recruit";
@import "page/contact";
@import "page/news";
@import "page/edit";


.site-module{
	&.wrap{
		padding: $pc-top $pc-side;
		@media screen and (max-width: $tab){
			padding: $tab-top $tab-side
		}
		@media screen and (max-width: $phone){
			padding: $sp-top $sp-side
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
		.sidewrap{
			padding: 0 4vw;
			@media screen and (max-width: $tab){
				padding: 0;	
			}
		}
	}
  h2,
	h3,
	h4{
		&.large{
			font-size: 1.2rem;
			font-weight: 700;
			margin: 0 0 16px;
			text-align: center;
			@media screen and (max-width: $phone){
				font-size: 1.16rem;
				margin: 6px 0 20px;
			}
			&:before{
				font-weight: 700;
				content: attr(data-eng);
				font-size: 3.24rem;
				margin: 0 0 4px;
				line-height: 1;
				display: block;
				@media screen and (max-width: $phone){
					font-size: 2rem;	
				}
			}
		}
    }
    h2,h3,h4{
        &.borders{
            border-bottom: $border 1px solid;
            position: relative;
            padding: 12px;
            padding-left: 20px;
            font-size: 1.64rem;
            line-height: 1;
            font-weight: 700;
            margin: 0 0 24px;
			@media screen and (max-width: $phone){
				margin: 0 0 15px;
				font-size: 1.24rem;
				padding-left: 18px;
			}
            &:after{
                content: '';
                width: 5px;
                height: 70%;
                background: $normal;
                border-radius: 4px;
                display: block;
                position: absolute;
                top: 15%;
                left: 0;
            }
			small{
				font-size: .96rem;
				display: inline-block;
				margin: 0 0 0 12px;
				@media screen and (max-width: $phone){
					font-size: .88rem;	
				}
			}
        }
	}

	.tablestyle{
		border: $border 1px solid;
		border-left: 0;
		border-right: 0;
		width: 100%;
		border-collapse: collapse;
		font-size: 1.08rem;
		@media screen and (max-width: $phone){
			display: block;
			border: $border 1px solid;
		}
		tbody{
			@media screen and (max-width: $phone){
				display: block;	
			}
			tr{
				border-bottom: $border 1px solid;
				@media screen and (max-width: $phone){
					display: block;
					&:last-child{
						border: none;
					}
				}
				&:nth-child(2n+2){
					background: lighten($gray, 2%);
					@media screen and (max-width: $phone){
						background: #FFF;	
					}
				}
				th,
				td{
					text-align: left;
					vertical-align: middle;
					padding: 32px 24px;
					@media screen and (max-width: $phone){
						display: block;
						padding: 12px $sp-side;
					}
				}
				th{
					width: 25%;
					@media screen and (max-width: $phone){
						width: auto;
						background: $gray;
					}
				}
			}
		}
	}
	.btnarea{
		margin: 24px 0 0;
		a{
			color: #FFF;
			border: $normal 1px solid;
			display: inline-block;
			padding: 10px 64px;
			font-size: 1.24rem;
			position: relative;
			transition: color 400ms $ease;
			&.block{
				display: block;
				padding: 10px 0;
				text-align: center;
			}
			&:before,
			&:after{
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: $normal;
				z-index: -1;
			}
			&:after{
				left: auto;
				right: 0;
				width: 0;
				background: #FFF;
				transition: width 400ms $ease;
			}
			&:hover{
				color: $normal;
				&:after{
					right: auto;
					left: 0;
					width: 100%;
				}
			}
			@media screen and (max-width: $phone){
				text-align: center;
				display: block;
				padding: 12px $sp-side;
			}
		}
	}
	.pager{
		margin: 48px 0 0;
		@include flex();
		justify-content: center;
		align-items: center;
		@media screen and (max-width: $phone){
			margin: 24px 0 0;	
		}
		a,
		span{
			$size: 36px;
			width: $size;
			height: $size;
			background: $gray;
			border: $gray 2px solid;
			border-radius: 50%;
			font-size: 1.12rem;
			@include flex();
			justify-content: center;
			align-items: center;
			margin: 0 5px;
			color: $normal;
			transition: all 120ms $ease;
		}
		a{
			&:hover{
				border-color: $normal;
				color: $normal;
				transform: scale(1.1);
			}
		}
		span{
			background: $normal;
			border-color: $normal;
			color: #FFF;
		}
	}
}

.site-clum{
	@include flex();
	align-items: flex-start;
	@media screen and (max-width: $tab){
		display: block;	
	}
	.sidebar{
		width: 260px;
		order: 1;
		position: sticky;
		top: 80px;
		@media screen and (max-width: $tab){
			position: relative;
			top: 0;
			width: auto;
			margin: 40px 0 0;
		}
		&__box{
			margin: 0 0 48px;
			.btnarea{
				margin: 20px 0 0;
			}
			&:last-of-type{
				margin: 0;
			}
			&__list{
				li{
					margin: 0 0 16px;
					padding: 0 0 16px;
					border-bottom: $border 1px solid;
					&:last-child{
						margin: 0;
						padding: 0;
						border: none;
					}
					a{
						@include flex();
						figure{
							width: 70px;
							height: 70px;
							img{
								width: 100%;
								height: 100%;
								object-fit: cover;
								object-position: center;
							}
						}
						dl{
							line-height: 1.56;
							dt{
								font-size: .92rem;
								font-weight: 700;
								margin: 6px 0 0;
							}
							dd{
								background: darken($gray, 2%);
								padding: 4px 12px;
								font-size: .8rem;
								width: 88px;
								text-align: center;
							}
						}
					}
				}
			}
			&__cate{
				@include flex();
				justify-content: flex-start;
				li{
					margin: 0 8px 8px 0;
					a{
						display: block;
						background: $gray;
						color: $normal;
						padding: 0 20px;
						border-radius: 4px;
						&:hover{
							background: $normal;
							color: #FFF;
						}
					}
				}
			}
		}
	}
	.container{
		width: calc(100% - 260px);
		padding: 0 0 0 56px;
		order: 2;
		@media screen and (max-width: $tab){
			width: auto;
			padding: 0;
		}
	}
}

.site-404{
	dl{
		text-align: center;
		dt{
			font-size: 1.44rem;
			font-weight: 700;
		}
		dd{
			
		}
	}
}

.fade-enter-active, .fade-leave-active{
	transition: opacity 600ms;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
